<template>
    <div>
        <div @click="$router.push('/')" class="flex text-gray-emb items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#525A60" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-gray-emb">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] border-[#fff] pb-2 text-gray-emb text-center w-[90%] mx-auto">
            {{ store.currentDayTrainings[0].date.split('T')[0].split('-').reverse().join('.') }}
        </div>

        <div class="mt-[20px]">
            <SquircleComponent
                class="mt-[22px] relative mx-auto"
                :smooth="70" 
                :radius="44" 
                :width="354" 
                :height="78" 
                :outlineWidth="0"
                v-for="training in store.currentDayTrainings"
                @click="$router.push('/all-client-on-training/' + training.training_id)"
                :class="{
                    'bg-[#DBEBEA]' : training.status == 'completed',
                    'bg-violet-emb bg-opacity-20' : training.status == 'planed' 
                }"
            >
                    <div class="flex text-[#4A4E69] relative top-[15px] items-center ml-[10px] mr-[16px] ">
                        <div class="w-full pt-[3px] flex items-center">
                            <div class="w-[52.1px] h-[44px] mr-[10px] relative bottom-[5px] rounded-full overflow-hidden border-[2px] border-[#525A60] ">
                                <img v-if="training?.trainer_photo" class="w-full h-full bg-white " :src="apiURL + 'static/' + training?.trainer_photo">
                                <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ training.trainer_label.split(' ')[0][0].toUpperCase() + training.trainer_label.split(' ')[1][0].toUpperCase() }}</div>
                            </div>
                            <div class="w-full mt-[px]">
                                <div class="w-full border-opacity-20 pb-[10px]">
                                    <div class="flex w-full">
                                        <div class="font-bold relative text-[16px] text-gray-emb bottom-[5px] w-[37%]">{{ training.trainer_label.split(' ')[0] + ' ' + training.trainer_label.split(' ')[1][0] + '.'  }}</div>
                                        <div class="font-bold text-[13px] relative text-gray-emb bottom-[1px]">{{ training.tariff_group_name }}</div>
                                    </div>
                                    <div class="flex justify-start w-full">
                                        <div class="flex w-[37%] relative top-[6px]">
                                            <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.start_time }}</div>
                                            <div class="mx-[3px] text-[11px] text-gray-emb">
                                                <svg class="scale-50 origin-center relative top-[2px]" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 11L5.29289 6.70711C5.62623 6.37377 5.79289 6.20711 5.79289 6C5.79289 5.79289 5.62623 5.62623 5.29289 5.29289L1 1" stroke="#525A60" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                            <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.end_time }}</div>
                                        </div>
                                        <div class="text-start text-[11px] text-gray-emb relative top-[6px] font-bold">{{ training.type }}</div>
                                        <!-- <div class="truncate text-[#6BA292] font-semibold w-[120px]">{{ 0 }}/{{ training.max_clients }}</div> -->
                                        <div class="flex ml-auto mt-[-13px]">
                                            <div class="text-[11px] mr-[12px] mt-[4px] text-gray-emb">
                                                {{training.location_label}}
                                            </div>
                                            <div 
                                                class="truncate text-[#6BA292] font-bold"
                                                :class="{
                                                    'text-[#6BA292]' : training.clients_count < training.max_clients,
                                                    'text-[#BF637D]' : training.clients_count >= training.max_clients
                                                }"    
                                            >{{ training.clients_count }}/{{ training.max_clients }}</div>
                                            <div 
                                                class="h-[22px] w-[22px] border-[2px] ml-[5px] rounded-full"
                                                :class="{
                                                    'bg-[#6BA292] border-[#6BA292]' : training.clients_count < training.max_clients,
                                                    'bg-[#BF637D] border-[#BF637D]' : training.clients_count >= training.max_clients
                                                }"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </SquircleComponent>
        </div>

        <UpdateTrainingForm 
            class="absolute bottom-0 w-full"
            v-if="planedTrainingFlag"
            @close="planedTrainingFlag = false"
            @updateTrainings="$event => $router.push('/')"
            :selectedTraining = "planedTrainingToUpdate"
        />
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'
    import UpdateTrainingForm from '../components/NewTraining/UpdateTrainingForm.vue'
    import SquircleComponent from '../components/SquircleComponent.vue'
    import { countClientsTrainingById } from '../services/apiRequests'

    export default {
        name: 'FullDayTrainingView',

        props: {
            date: {
                type: Date,
                required: true
            }
        },

        setup(props) {
            const store = useMainStore()
            const router = useRouter()

            const planedTrainingToUpdate = ref(null)
            const planedTrainingFlag = ref(false) 

            const apiURL = process.env.VUE_APP_API_URL

            const selectTraining = (training) => {
                if (training.status == 'completed') {
                    store.currentCompletedTraining = training
                    router.push('/completed-training-view')
                } else if (training.status == 'planed') {
                    planedTrainingToUpdate.value = training.training_id
                    planedTrainingFlag.value = true
                }
            } 

            onMounted(async () => {
                for (let i = 0; i < store.currentDayTrainings.length; i++) {
                    store.currentDayTrainings[i].clients_count = (await countClientsTrainingById(store.currentDayTrainings[i].training_id)).data
                }
            })

            return {
                store,
                selectTraining,
                planedTrainingToUpdate,
                planedTrainingFlag,
                apiURL
            }
        },

        components: {
            UpdateTrainingForm,
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>

</style>