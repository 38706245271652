<template>
    <div v-if="loadingSpinnerFlag" class="bg-[#FFF] z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div class="bg-[#FFF] pb-6">
        <trainer-preview />
        <trainer-data />
        <trainings-block />
        <div class="text-gray-emb mt-[22px] flex justify-between mb-[0px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[12px] font-semibold">КАЛЕНДАР ТРЕНУВАНЬ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>
        <TrainingCalendar 
            class="scale-[0.95]"
            v-if="!calendarDataLoadingFlag" 
            :planedTrainings="trainerPlanedTrainings" 
            :completedTrainings="trainerCompletedTrainings"
            @allDayTraining="parseTrainingsForDay" 
        />
        <MyStatisticsView />
        <!-- <trainer-finance /> -->

        <button @click="logout" class="bg-red-atten rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
            <div class="w-full">Вийти</div>
        </button>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'
    import { getCompletedTrainingsByGroup, getTrainerPlanedTrainings, getTrainerCompletedTrainings, countClientsTrainingById } from '../services/apiRequests'

    import TrainerPreview from '../components/TrainerPreview/TrainerPreview.vue'
    import TrainerData from '../components/TrainerData/TrainerData.vue'
    import TrainingsBlock from '../components/TrainingsBlock/TrainingsBlock.vue'
    import TrainerFinance from '../components/TrainerFinance/TrainerFinance.vue'
    import TrainingCalendar from '../components/TrainingCalendar/TrainingCalendar.vue'
    import MyStatisticsView from '../components/MyStatistics/MyStatisticsView.vue'

    export default {
        name: 'main-view',

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const loadingSpinnerFlag = ref(true)
            const planedTrainingDates = ref([])
            const calendarDataLoadingFlag = ref(true)
            const completedTrainings = ref([])
            const completedTrainingsDates = ref([])

            const trainerPlanedTrainings = ref([])
            const trainerCompletedTrainings = ref([])

            const logout = () => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                router.go()
            }

            const parseTrainingsForDay = (trainings) => {


                store.currentDayTrainings = trainings
                store.currentDayForFullView = ''
                router.push('/full-day-training')
            }

            onMounted(async () => {
                await store.getTrainerData()
                await store.getStudentBalanceData()
                await store.getServiceBalanceData()
                await store.getTrainerGroups()
                // await store.getAllTrainerPlanedTrainings()
                // await store.getTrainerRealIncomes()
                // await store.getTrainerTransactions()

                trainerPlanedTrainings.value = (await getTrainerPlanedTrainings(store.trainer.trainer_id)).data
                trainerCompletedTrainings.value = (await getTrainerCompletedTrainings(store.trainer.trainer_id)).data

                console.log('trainerPlanedTrainings.value ------- ', trainerPlanedTrainings.value)
                console.log('trainerCompletedTrainings.value ------- ', trainerCompletedTrainings.value)

                // planedTrainingDates.value = store.allTrainings.map(training => training.date.split('T')[0])

                // for (let i = 0; i < store.trainer_groups.length; i++) {
                //     const response = await getCompletedTrainingsByGroup(store.trainer_groups[i].group_id)
                //     console.log('store.trainer_groups[i].group_id ------- ', store.trainer_groups[i].name)
                //     console.log('response.data ------- ', response.data)
                //     completedTrainings.value.push(...response.data)
                // }

                // completedTrainingsDates.value = completedTrainings.value.map(training => training.date.split('T')[0])

                calendarDataLoadingFlag.value = false
                loadingSpinnerFlag.value = false
            })

            return {
                logout,
                loadingSpinnerFlag,
                store,
                planedTrainingDates,
                calendarDataLoadingFlag,
                completedTrainings,
                completedTrainingsDates,
                parseTrainingsForDay,
                trainerPlanedTrainings,
                trainerCompletedTrainings
            }
        },

        components: {
            TrainerPreview,
            TrainerData,
            TrainingsBlock,
            TrainerFinance,
            TrainingCalendar,
            MyStatisticsView
        }
    }
</script>
